<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">TPR хүргэлтийн төлбөр</h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="showAddressDialog = true"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <div class="panel">
      <el-table size="mini" :data="tprDeliveryPrice" style="width: 100%">
        <el-table-column label="Нэр" width="140px">
          <template slot-scope="scope">
            <span
              >H{{
                scope.row.is_default === 1
                  ? scope.row.hexa_name + " (үндсэн тохиргоо)"
                  : scope.row.hexa_name
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="Тайлбар" prop="desc"></el-table-column>
        <el-table-column width="170px" label="Үйлдэл" align="center">
          <template slot-scope="scope">
            <el-row :gutter="20">
              <el-col :span="scope.row.is_deafult === 1 ? 24 : 12">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onEditClick(scope.row)"
                  >Засах</el-button
                >
              </el-col>
              <el-col :span="12" v-if="scope.row.is_default !== 1">
                <el-button
                  type="danger"
                  size="mini"
                  @click="deleteHexagon(scope.row.hexa_name)"
                  >Устгах</el-button
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="TPR хүргэлтийн төлбөр"
      :visible.sync="showAddressDialog"
      width="70%"
      @close="onCloseDialog()"
    >
      <el-form :model="tprData" :rules="rules" ref="tprData" size="small">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Hexagon нэр: "
              label-width="130px"
              prop="hexa_name"
            >
              <el-select v-model="tprData.hexa_name" @change="setZoomSize">
                <el-option
                  v-for="(hexaName, indexHexa) in optionsHexa"
                  :key="indexHexa"
                  :label="'H' + hexaName.label"
                  :value="hexaName.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="Ажиллах өдөр: "
              prop="weeking_days"
              label-width="130px"
            >
              <el-select
                v-model="tprData.weeking_days"
                multiple
                placeholder="Өдөр сонгох"
              >
                <el-option
                  v-for="(day, indexDay) in optionsDay"
                  :key="indexDay"
                  :label="day.label"
                  :value="day.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Төлөв: " label-width="120px">
              <el-switch
                style="display: block"
                v-model="tprData.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="Идэвхтэй"
                inactive-text="Идэвхгүй"
                :active-value="true"
                :inactive-value="false"
              >
              </el-switch>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Цаг: "
                  label-width="120px"
                  prop="start_time"
                >
                  <el-time-picker
                    size="mini"
                    value-format="HH:mm:ss"
                    v-model="tprData.start_time"
                    placeholder="Эхлэх цаг сонгох"
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="end_time" class="ml20">
                  <el-time-picker
                    size="mini"
                    value-format="HH:mm:ss"
                    v-model="tprData.end_time"
                    placeholder="Дуусах цаг сонгох"
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Өдөр: "
                  label-width="120px"
                  prop="start_date"
                >
                  <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="tprData.start_date"
                    type="datetime"
                    placeholder="Эхлэх өдөр"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="end_date" class="ml20">
                  <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="tprData.end_date"
                    type="datetime"
                    placeholder="Дуусах өдөр"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-form-item
              label="Нэмэлт үнэ"
              label-width="120px"
              prop="add_price"
            >
              <el-input
                v-model="tprData.add_price"
                placeholder="3000"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="Үнэ" label-width="120px" prop="base_price">
              <el-input
                v-model="tprData.base_price"
                placeholder="5000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Нэмэлт үнэ"
              label-width="120px"
              prop="additional_price"
            >
              <el-input
                v-model="tprData.additional_price"
                placeholder="0"
              ></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item
                  label="2-3 км"
                  label-width="120px"
                  prop="km_price"
                >
                  <el-input
                    v-model="tprData.km_price"
                    placeholder="Ачаа тээвэр 2-3 км үнэ"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="10">
                <el-form-item prop="company_driver_km_price">
                  <el-input
                    v-model="tprData.company_driver_km_price"
                    placeholder="Үндсэн жолооч 2-3 км үнэ"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item
                  label="3-с дээш км"
                  label-width="120px"
                  prop="price_3_km"
                >
                  <el-input
                    v-model="tprData.price_3_km"
                    placeholder="Ачаа тээвэр 3-с дээш км үнэ"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="10">
                <el-form-item prop="company_driver_3_km_price">
                  <el-input
                    v-model="tprData.company_driver_3_km_price"
                    placeholder="Үндсэн жолооч 3-с дээш км үнэ"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item
                  label="TPR min-max"
                  label-width="120px"
                  prop="tplh_min"
                >
                  <el-input
                    v-model="tprData.tplh_min"
                    placeholder="1"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="tplh_max">
                  <el-input
                    v-model="tprData.tplh_max"
                    placeholder="2"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              label="Нэмэгдэх мин"
              label-width="120px"
              prop="delivery_time"
            >
              <el-input
                v-model="tprData.delivery_time"
                placeholder="25"
              ></el-input>
            </el-form-item>
            <el-form-item label="Илгээх мэссеж: " label-width="120px">
              <el-input
                v-model="tprData.msg_text"
                type="textarea"
                placeholder="Апп дээр харагдах текст ..."
              ></el-input>
            </el-form-item>
            <el-form-item label="Тайлбар: " label-width="120px">
              <el-input
                v-model="tprData.desc"
                type="textarea"
                placeholder="Тайлбар оруулах...."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              H3: {{ hexagonResourceSize }}
              <div class="partner-google-map">
                <gmap-map
                  ref="map"
                  :center="center"
                  :zoom="zoomSize"
                  :options="mapOptions"
                  :draggable="true"
                  style="width:auto;  height: 50vh;"
                >
                  <gmap-marker
                    :position="position"
                    :clickable="true"
                    :draggable="true"
                  />
                  <gmap-polygon
                    v-for="(item, index) in getDefaultHexagonList()"
                    :key="index"
                    :paths="item.points"
                    :options="{
                      fillColor: hexagon_zone.allowed_list.includes(item.label)
                        ? '#000000'
                        : '#FFFFFF',
                      strokeOpacity: 0.2,
                      fillOpacity: getPolygonFillOpacity(
                        'allowed_list',
                        item.label
                      )
                        ? 0.5
                        : 0.1
                    }"
                    :editable="item.editable"
                  >
                  </gmap-polygon>
                </gmap-map>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">Хаах</el-button>
        <el-button type="primary" @click="createTprDeliveryPrice(tprData)"
          >Үүсгэх</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { h3ToGeoBoundary, h3ToChildren } from "h3-js";
export default {
  name: "tprDeliveryPrice",
  mounted() {
    this.getTprDeliveryPrice();
    this.getDefaultHexagonList();
  },
  data() {
    return {
      UBHexagonList: [
        "86255a38fffffff",
        "86255a217ffffff",
        "86255a39fffffff",
        "86255a2a7ffffff",
        "86255a3afffffff",
        "86255a237ffffff",
        "86255a387ffffff"
      ],
      optionsDay: this.setOptionsDay(),
      optionsHexa: this.setOptionsHexa(),
      zoomSize: 11,
      hexagonResourceSize: 8,
      tprData: {
        hexa_name: 8
      },
      showAddressDialog: false,
      loading: false,
      tprDeliveryPrice: [],
      center: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      position: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      hexagon_zone: {
        allowed_list: [],
        blocked_list: []
      },
      rules: {
        hexa_name: [
          {
            required: true,
            message: "Та нэрээ оруулна уу",
            trigger: "blur"
          }
        ],
        start_date: [
          {
            required: true,
            message: "Та эхлэх өдөр оруулна уу",
            trigger: "blur"
          }
        ],
        end_date: [
          {
            required: true,
            message: "Та дуусах өдөр оруулна уу",
            trigger: "blur"
          }
        ],
        start_time: [
          {
            required: true,
            message: "Та эхлэх цагаа оруулна уу",
            trigger: "blur"
          }
        ],
        end_time: [
          {
            required: true,
            message: "Та дуусах цагаа оруулна уу",
            trigger: "blur"
          }
        ],
        tplh_min: [
          {
            required: true,
            message: "TPR min оруулна уу?",
            trigger: "blur"
          }
        ],
        tplh_max: [
          {
            required: true,
            message: "TPR max оруулна уу?",
            trigger: "blur"
          }
        ],
        // add_price: [
        //   {
        //     required: true,
        //     message: "Нэмэлт үнэ оруулна уу",
        //     trigger: "blur"
        //   }
        // ],
        base_price: [
          {
            required: true,
            message: "Үндсэн үнэ оруулна уу",
            trigger: "blur"
          }
        ],
        km_price: [
          {
            required: true,
            message: "Ачаа тээвэр 2-3 км үнэ оруулна уу",
            trigger: "blur"
          }
        ],
        delivery_time: [
          {
            required: true,
            message: "Нэмэгдэх минут оруулна уу",
            trigger: "blur"
          }
        ],
        weeking_days: [
          {
            required: true,
            message: "Өдрөө сонгоно уу?",
            trigger: "blur"
          }
        ],
        price_3_km: [
          {
            required: true,
            message: "Ачаа тээвэр 3-с дээш км үнэ оруулна уу?",
            trigger: "blur"
          }
        ],
        company_driver_3_km_price: [
          {
            required: true,
            message: "Үндсэн жолооч 3-с дээш км үнэ оруулна уу?",
            trigger: "blur"
          }
        ],
        company_driver_km_price: [
          {
            required: true,
            message: "Үндсэн жолооч 2-3 км үнэ оруулна уу?",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    getDefaultHexagonList() {
      const currentHexagonSize = parseInt(this.tprData.hexa_name);
      const hexagonList = [];
      this.UBHexagonList.forEach(el => {
        let hexagons = h3ToChildren(el, currentHexagonSize);
        hexagonList.push(...hexagons);
      });
      return this.generatePolygonsFromHexagonList(hexagonList);
    },

    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        tempData.push(temp);
      });
      return tempData;
    },

    getPolygonFillOpacity(type) {
      if (type === "allowed_list") {
        return true;
      }
      return false;
    },

    onCloseDialog() {
      this.showAddressDialog = false;
      this.$refs["tprData"].resetFields();
    },

    setOptionsDay() {
      return [
        {
          value: "Mon",
          label: "Даваа"
        },
        {
          value: "Tue",
          label: "Мягмар"
        },
        {
          value: "Wed",
          label: "Лхагва"
        },
        {
          value: "Thu",
          label: "Пүрэв"
        },
        {
          value: "Fri",
          label: "Баасан"
        },
        {
          value: "Sat",
          label: "Бямба"
        },
        {
          value: "Sun",
          label: "Ням"
        }
      ];
    },

    setOptionsHexa() {
      const tempData = [];
      for (let i = 5; i < 10; i++) {
        tempData.push({ label: i + 1, value: "H" + (i + 1) });
      }
      return tempData;
    },

    setZoomSize(size) {
      this.hexagonResourceSize = size;
    },

    onEditClick(data) {
      this.$router.push({
        path: "tpr-delivery-price-detail",
        query: {
          name: data.hexa_name,
          default: data.is_default
        }
      });
    },

    createTprDeliveryPrice(data) {
      data.add_price = parseInt(data.add_price);
      data.tplh_min = parseInt(data.tplh_min);
      data.tplh_max = parseInt(data.tplh_max);
      data.base_price = parseInt(data.base_price);
      data.delivery_time = parseInt(data.delivery_time);
      data.km_price = parseInt(data.km_price);
      data.price_3_km = parseInt(data.price_3_km);
      data.company_driver_3_km_price = parseInt(data.company_driver_3_km_price);
      data.company_driver_km_price = parseInt(data.company_driver_km_price);
      data.hexa_name = parseInt(data.hexa_name);
      data.additional_price = parseInt(data.additional_price);
      this.$refs.tprData.validate(valid => {
        if (valid) {
          this.openFullScreenLoader(true);
          service.createTprDeliveryPriceDetail(data).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: response.data.message,
                type: "success"
              });
              this.openFullScreenLoader(false);
              this.getTprDeliveryPrice();
              this.showAddressDialog = false;
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message: response.data.message,
                type: "warning"
              });
              this.openFullScreenLoader(false);
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          this.openFullScreenLoader(false);
        }
      });
    },

    getTprDeliveryPrice() {
      this.openFullScreenLoader(true);
      service.getTprDeliveryPrice().then(response => {
        if (response.data.status === "success") {
          this.tprDeliveryPrice = response.data.data;
          this.openFullScreenLoader(false);
        } else {
          this.openFullScreenLoader(false);
        }
      });
    },

    deleteHexagon(hexaName) {
      this.openFullScreenLoader(true);
      const body = {
        hexa_name: parseInt(hexaName)
      };
      this.$confirm("TPR жагсаалт устгахдаа итгэлтэй байна уу?", "Анхаар", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteTprDeliveryPriceList(body).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: response.data.message,
                type: "success"
              });
              this.openFullScreenLoader(false);
              this.getTprDeliveryPrice();
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message: response.data.message,
                type: "warning"
              });
              this.openFullScreenLoader(false);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
          this.openFullScreenLoader(false);
        });
    },

    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    }
  }
};
</script>

<style></style>
